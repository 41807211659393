
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate, useParams } from 'react-router-dom';
import ReactQuill from 'react-quill';
import OutlinedInput from '@mui/material/OutlinedInput';

import Chip from '@mui/material/Chip';
import { useDispatch, useSelector } from 'react-redux';
import { createJob, getJobDetails, resetJobCreate, resetJobCreateForm, resetJobUpdate, updateForm, updateJob } from '../../actions/jobActions';
import { Fragment, useState } from 'react';
import { Autocomplete, Checkbox, FormControl, FormControlLabel, FormGroup, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { getUserDetails, setEditing, setItem } from '../../actions/userActions';
import { ToastContainer, toast } from 'react-toastify';
import { industries, jobTitles, professionalSkills, isEmpty, acceptOnlyNumbers, countries } from '../../utils/Validate';





const steps = ['Basic Information', 'Additional Information', 'Review Information'];



const ITEM_HEIGHT = 40;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};






export default function HorizontalLinearStepper() {
    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = useState(new Set());
    const { id } = useParams()

    const dispatch = useDispatch();
    const { formData } = useSelector(state => state.formReducer);
    const { loading, success, error } = useSelector(state => state.jobCreate)
    const { success: successUpdate, loading: loadingUpdate } = useSelector(state => state.jobUpdate)
    const { job } = useSelector(state => state.jobDetails)
    const { user } = useSelector((state) => state.userDetails);
    const { userInfo } = useSelector((state) => state.userLogin);
    const { isEditing } = useSelector(state => state.editingState)

    const navigate = useNavigate()
    const [company, setCompany] = useState(user ? user.company : formData ? formData.company : '')
    const [title, setTitle] = useState(job ? job.title : formData ? formData.title : '')
    const [location, setLocation] = useState(job ? job.location : formData ? formData.location : '')
    const [address, setAddress] = useState(job ? job.address : formData ? formData.address : '')
    const [industry, setIndustry] = useState(job ? job.industry : formData ? formData.industry : '')
    const [qualification, setQualification] = useState(job ? job.qualification : formData ? formData.qualification : '')
    const [grade, setGrade] = useState(job ? job.grade : formData ? formData.grade : '')
    const [experienceLevel, setExperienceLevel] = useState(job ? job.experienceLevel : formData ? formData.experienceLevel : '')
    const [gradeOption, setgradeOption] = useState(false)
    const [type, setType] = useState(job ? job.type : formData ? formData.type : '')
    const [mode, setMode] = useState(job ? job.mode : formData ? formData.mode : '')
    const [minSalary, setMinSalary] = useState(job ? job.minSalary : formData ? formData.minSalary : 0)
    const [maxSalary, setMaxSalary] = useState(job ? job.maxSalary : formData ? formData.maxSalary : 0)
    const [frequency, setFrequency] = useState(job ? job.frequency : formData ? formData.frequency : '')
    const [description, setDescription] = useState(job ? job.description : formData ? formData.description : '')
    const [requirement, setRequirement] = useState(job ? job.requirement : formData ? formData.requirement : '')
    const [otherTitle, setOtherTitle] = useState(formData ? formData.otherTitle : "")



    const theme = useTheme();
    const [jobSkill, setJobSkill] = useState(job?.skills || formData?.skills || []);



    function getStyles(skill, jobSkill, theme) {
        return {
            fontWeight:
                jobSkill.indexOf(skill) === -1
                    ? theme.typography.fontWeightRegular
                    : theme.typography.fontWeightMedium,
        };
    }


    // const handleChange = (event) => {
    //     const {
    //         target: { value },
    //     } = event;
    //     setJobSkill(
    //         // On autofill we get a stringified value.
    //         typeof value === 'string' ? value.split(',') : value,
    //     );
    // };
    const handleChange = (event, newValue) => {
        setJobSkill(newValue);
    };





    const isStepOptional = (step) => {
        return step === -1;
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleSubmit = (e) => {
        e.preventDefault()
        if (isEditing) {

            dispatch(updateJob(job._id, formData))
            dispatch(setEditing(false))
        } else {
            dispatch(createJob(formData))
        }


    }
    const handleStepOne = (e) => {
        e.preventDefault()
        const requiredFields = [company, title, industry, location, address, qualification, experienceLevel];
        const areFieldsEmpty = requiredFields.some(isEmpty);

        if (areFieldsEmpty) {
            return toast("Please Fill all fields", {
                className: 'toast-failed',
                bodyClassName: 'toast-failed',
            });
        }

        if (otherTitle) {
            dispatch(updateForm({ company, title: otherTitle, industry, location, address, qualification, grade, experienceLevel, avatar: user.avatar }))
        } else {
            dispatch(updateForm({ company, title, industry, location, address, qualification, grade, experienceLevel, avatar: user.avatar }))
        }

        handleNext()

    }
    const handleStepTwo = (e) => {
        e.preventDefault()
        const requiredFields = [type, mode, frequency, description, requirement];
        const areFieldsEmpty = requiredFields.some(isEmpty);

        if (areFieldsEmpty || jobSkill.length === 0) {
            return toast("Please Fill all fields", {
                className: 'toast-failed',
                bodyClassName: 'toast-failed',
            });
        }

        if (minSalary && maxSalary) {

            if (Number(maxSalary) <= Number(minSalary)) {
                return toast("Maximum Pay must be greater than Minimum Pay", {
                    className: 'toast-failed',
                    bodyClassName: 'toast-failed',
                });
            }
        }


        dispatch(updateForm({ type, mode, minSalary, maxSalary, frequency, description, requirement, skills: jobSkill }))
        handleNext()

    }



    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    React.useEffect(() => {

        if (!user || !user.company) {
            dispatch(getUserDetails("profile"))
        }


        if (success) {
            toast("Job Created Successfully", {
                className: 'toast-success',
                bodyClassName: 'toast-success',
            });
            setTimeout(() => {
                dispatch(setItem("jobs"))
                dispatch(resetJobCreateForm())
                dispatch(resetJobCreate())
                navigate("/jobs")

            }, 2000);


        }
        if (successUpdate) {
            toast("Job Updated Successfully", {
                className: 'toast-success',
                bodyClassName: 'toast-success',
            });

            setTimeout(() => {
                dispatch(resetJobCreateForm())
                dispatch(resetJobUpdate())
                dispatch(setItem("jobs"))
                navigate("/jobs")
            }, 2000);

        }


        if (job._id || job._id !== id) {

            dispatch(getJobDetails(id))

        }



    }, [dispatch, navigate, userInfo, user, success, id, successUpdate, job._id])

    return (
        <Box sx={{ width: '100%', mt: 5 }}>

            {error && toast(error, {
                className: 'toast-error',
                bodyClassName: 'toast-error',
            })
            }

            <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    if (isStepOptional(index)) {
                        labelProps.optional = (
                            <Typography variant="caption">Optional</Typography>
                        );
                    }
                    if (isStepSkipped(index)) {
                        stepProps.completed = false;
                    }
                    return (
                        <Step key={label} {...stepProps} >
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            {activeStep === steps.length ? (
                <Fragment>
                    <Typography sx={{ mt: 2, mb: 1 }}>
                        Nicely Done! You have successfully posted a new Job
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <Button onClick={handleReset}>Reset</Button>
                    </Box>
                </Fragment>
            ) : (
                <Fragment>
                    <ToastContainer />

                    {activeStep === 0 &&
                        <Box sx={{ marginTop: "15px" }}>


                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                value={company}
                                onChange={(e) => setCompany(e.target.value)}
                                label="Company"
                                sx={{ mb: 2 }}
                                autoComplete="Company"
                                autoFocus
                            />
                            {!isEditing ? (
                                <FormControl fullWidth sx={{ my: 2 }}>
                                    {/* <InputLabel id="job-title">Job Title</InputLabel>
                                    <Select
                                        labelId="job-title"
                                        id="select-job-title"
                                        MenuProps={MenuProps}
                                        value={title}

                                        onChange={(e) => setTitle(e.target.value)}
                                        label="Job Title"
                                        required

                                    >

                                        {jobTitles.map(title => (
                                            <MenuItem value={title} key={title}>{title}</MenuItem>
                                        ))}

                                    </Select> */}
                                    <Autocomplete
                                        id="select-JobTitle"
                                        options={jobTitles}
                                        value={title}
                                        onChange={(event, newValue) => setTitle(newValue)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Job Title"
                                                required
                                                fullWidth
                                                placeholder='Start Typing...'
                                            />
                                        )}
                                        isOptionEqualToValue={(option, value) => option === value}
                                        getOptionLabel={(option) => option}
                                    />
                                </FormControl>
                            ) : (
                                <TextField
                                    margin="normal"
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                    fullWidth

                                    required
                                    label="Job Title"

                                />
                            )}
                            {title === "Other" && (<FormControl fullWidth sx={{ mb: 2 }}>

                                <TextField
                                    margin="normal"
                                    value={otherTitle}
                                    onChange={(e) => setOtherTitle(e.target.value)}
                                    fullWidth
                                    sx={{ mt: 2 }}
                                    required
                                    label="Job Title"

                                />


                            </FormControl>
                            )}
                            <FormControl fullWidth sx={{ my: 2 }}>
                                {/* <InputLabel id="state-residence">Industry</InputLabel>
                                <Select
                                    labelId="location"
                                    id="select-location"
                                    required
                                    fullWidth
                                    MenuProps={MenuProps}
                                    value={industry}
                                    onChange={(e) => setIndustry(e.target.value)}
                                    label="Industry"
                                    sx={{ mb: 2 }}
                                    autoComplete="Industry"

                                >

                                    {industries.map(industry => (
                                        <MenuItem value={industry} key={industry}>{industry}</MenuItem>
                                    ))}


                                </Select> */}
                                <Autocomplete
                                    id="select-Industry"
                                    options={industries}
                                    value={industry}
                                    onChange={(event, newValue) => setIndustry(newValue)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Industry"
                                            required
                                            fullWidth
                                            placeholder='Start Typing...'
                                        />
                                    )}
                                    isOptionEqualToValue={(option, value) => option === value}
                                    getOptionLabel={(option) => option}
                                />
                            </FormControl>
                            <FormControl fullWidth sx={{ my: 2 }}>
                                <Autocomplete
                                    id="select-location"
                                    options={countries}
                                    value={location}
                                    onChange={(event, newValue) => setLocation(newValue)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Location"
                                            required
                                            fullWidth
                                            placeholder='Start Typing...'
                                        />
                                    )}
                                    isOptionEqualToValue={(option, value) => option === value}
                                    getOptionLabel={(option) => option}
                                />
                            </FormControl>

                            <TextField
                                margin="normal"
                                value={address}
                                onChange={(e) => setAddress(e.target.value)}
                                label="Office Address"
                                fullWidth
                                sx={{ my: 2 }}
                                required


                            />
                            <FormControl fullWidth sx={{ my: 2 }}>
                                <InputLabel id="qualification">Qualification</InputLabel>
                                <Select
                                    labelId="qualification"
                                    id="select-qualification"
                                    value={qualification}
                                    label="Qualification"
                                    required
                                    onChange={(e) => setQualification(e.target.value)}
                                >

                                    <MenuItem value="HND/BSC">HND / BSC</MenuItem>
                                    <MenuItem value="MSc">MSc / MBA</MenuItem>
                                    <MenuItem value="PhD">PhD</MenuItem>
                                    <MenuItem value="Other">Other</MenuItem>

                                </Select>
                            </FormControl>

                            <FormGroup sx={{ mt: 2 }}>

                                <FormControlLabel control={<Checkbox
                                    checked={gradeOption}

                                    onChange={() => setgradeOption(!gradeOption)}
                                />} label="Add Grade to this Job" />
                            </FormGroup>

                            {gradeOption && (
                                <FormControl fullWidth sx={{ my: 2 }}>
                                    <InputLabel id="grade">Grade</InputLabel>
                                    <Select
                                        labelId="grade"
                                        id="select-grade"
                                        value={grade}
                                        label="Grade"
                                        required
                                        onChange={(e) => setGrade(e.target.value)}
                                    >

                                        <MenuItem value="First Class">First Class</MenuItem>
                                        <MenuItem value="Second Class Upper">Second Class Upper</MenuItem>
                                        <MenuItem value="Second Class Lower">Second Class Lower</MenuItem>
                                        <MenuItem value="Third Class">Third Class</MenuItem>

                                    </Select>
                                </FormControl>

                            )}
                            <FormControl fullWidth sx={{ my: 2 }}>
                                <InputLabel id="experience-level">Experience Level</InputLabel>
                                <Select
                                    labelId="experience-level"
                                    id="select-experience"
                                    value={experienceLevel}
                                    label="Experience Level"
                                    required
                                    onChange={(e) => setExperienceLevel(e.target.value)}
                                >

                                    <MenuItem value="Entry-level">Entry-Level</MenuItem>
                                    <MenuItem value="Intermediate">Intermediate</MenuItem>
                                    <MenuItem value="Lead">Lead</MenuItem>
                                    <MenuItem value="Manager">Manager</MenuItem>
                                    <MenuItem value="Senior Manager">Senior Manager</MenuItem>

                                </Select>
                            </FormControl>
                        </Box>
                    }
                    {activeStep === 1 && (
                        <Box sx={{ marginTop: "15px" }}>


                            <FormControl fullWidth sx={{ my: 2 }}>
                                <InputLabel id="employment-type">Employment Type</InputLabel>
                                <Select
                                    labelId="employment-type"
                                    id="select-employment-type"
                                    value={type}
                                    label="Employment Type"
                                    required
                                    onChange={(e) => setType(e.target.value)}
                                >

                                    <MenuItem value="Full-time">Full-Time</MenuItem>
                                    <MenuItem value="Part-Time">Part-Time</MenuItem>
                                    <MenuItem value="Freelance">Freelance</MenuItem>


                                </Select>
                            </FormControl>
                            <FormControl fullWidth sx={{ my: 2 }}>
                                <InputLabel id="work-mode">Work Environment</InputLabel>
                                <Select
                                    labelId="work-mode"
                                    id="select-work-mode"
                                    value={mode}
                                    required
                                    label="Work Environment"
                                    onChange={(e) => setMode(e.target.value)}
                                >

                                    <MenuItem value="Onsite">Onsite</MenuItem>
                                    <MenuItem value="Hybrid">Hybrid</MenuItem>
                                    <MenuItem value="Remote">Remote</MenuItem>


                                </Select>
                            </FormControl>
                            <Grid container >
                                <Grid xs={4} >
                                    <TextField
                                        margin="normal"
                                        value={minSalary && acceptOnlyNumbers(minSalary)}
                                        onChange={(e) => setMinSalary(e.target.value)}
                                        sx={{ width: "90%", marginRight: "5%" }}
                                        required
                                        label="Minimum Pay"

                                        autoComplete="Minimum Pay"
                                        autoFocus
                                    />
                                </Grid>
                                <Grid xs={4} >
                                    <TextField
                                        margin="normal"
                                        value={maxSalary && acceptOnlyNumbers(maxSalary)}
                                        onChange={(e) => setMaxSalary(e.target.value)}
                                        sx={{ width: "90%", marginRight: "5%" }}
                                        required
                                        label="Maximum Pay"

                                        autoComplete="Maximum Pay"
                                        autoFocus
                                    />
                                </Grid>
                                <Grid xs={4} sx={{ mt: 2, width: "90%", }}>
                                    <FormControl fullWidth>
                                        <InputLabel id="work-mode">Frequency</InputLabel>
                                        <Select
                                            labelId="frequency"
                                            id="select-frequency"
                                            value={frequency}
                                            label="Frequency"
                                            onChange={(e) => setFrequency(e.target.value)}
                                        >

                                            <MenuItem value="Per Month">Per Month</MenuItem>
                                            <MenuItem value="Per Year">Per Year</MenuItem>



                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>




                            {/* <TextField
                                id="outlined-multiline-static"
                                label="Job Description"
                                multiline
                                fullWidth
                                required
                                rows={4}
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                placeholder="List the details of this Job"
                                sx={{ mt: 2 }}
                            /> */}
                            <ReactQuill
                                value={description}
                                onChange={setDescription}
                                placeholder="List the details of this Job"
                                modules={{
                                    toolbar: [
                                        [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                        [{ size: [] }],
                                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                        [{ 'list': 'ordered' }, { 'list': 'bullet' },
                                        { 'indent': '-1' }, { 'indent': '+1' }],
                                        ['link', 'image', 'video'],
                                        ['clean']
                                    ],
                                }}
                                formats={[
                                    'header', 'font', 'size',
                                    'bold', 'italic', 'underline', 'strike', 'blockquote',
                                    'list', 'bullet', 'indent',
                                    'link', 'image', 'video'
                                ]}
                                style={{ height: '200px', marginTop: "20px" }}
                            />
                            {/* <TextField
                                id="outlined-multiline-static"
                                label="Requirements"
                                multiline
                                fullWidth
                                required
                                value={requirement}
                                onChange={(e) => setRequirement(e.target.value)}
                                rows={4}
                                placeholder="Outline the requirements for this Job"
                                sx={{ mt: "100px" }}
                            /> */}
                            <ReactQuill
                                value={requirement}
                                onChange={setRequirement}
                                placeholder="List the requirements for this Job"
                                modules={{
                                    toolbar: [
                                        [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                        [{ size: [] }],
                                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                        [{ 'list': 'ordered' }, { 'list': 'bullet' },
                                        { 'indent': '-1' }, { 'indent': '+1' }],
                                        ['link', 'image', 'video'],
                                        ['clean']
                                    ],
                                }}
                                formats={[
                                    'header', 'font', 'size',
                                    'bold', 'italic', 'underline', 'strike', 'blockquote',
                                    'list', 'bullet', 'indent',
                                    'link', 'image', 'video'
                                ]}
                                style={{ height: '200px', marginTop: "100px", marginBottom: "80px" }}
                            />

                            <div>
                                {/* <FormControl sx={{ mt: 2, width: "100%" }}>
                                    <InputLabel id="demo-multiple-chip-label">Skill</InputLabel>
                                    <Select
                                        labelId="demo-multiple-skill-label"
                                        id="demo-multiple-skill"
                                        multiple
                                        value={jobSkill}
                                        onChange={handleChange}
                                        input={<OutlinedInput id="select-multiple-chip" label="Skill" />}
                                        renderValue={(selected) => (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                {selected.map((value) => (
                                                    <Chip key={value} label={value} />
                                                ))}
                                            </Box>
                                        )}
                                        MenuProps={MenuProps}
                                    >
                                        {professionalSkills.map((skill) => (
                                            <MenuItem
                                                key={skill}
                                                value={skill}
                                                style={getStyles(skill, jobSkill, theme)}
                                            >
                                                {skill}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl> */}

                                <Autocomplete
                                    multiple
                                    options={professionalSkills}
                                    value={jobSkill}
                                    onChange={handleChange}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Skill"
                                            placeholder="Start typing..."
                                        />
                                    )}
                                    renderTags={(selected, getTagProps) =>
                                        selected.map((value, index) => (
                                            <Chip key={value} label={value} {...getTagProps({ index })} />
                                        ))
                                    }
                                    filterSelectedOptions
                                    sx={{ mt: 2, width: "100%" }}
                                />
                            </div>




                        </Box>
                    )}
                    {activeStep === 2 &&
                        (
                            <Box sx={{ marginTop: "15px" }}>


                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    value={formData.company}
                                    onChange={(e) => setCompany(e.target.value)}
                                    label="Company"
                                    sx={{ mb: 2 }}
                                    autoComplete="Company"
                                    autoFocus
                                />


                                {/* <FormControl fullWidth sx={{ my: 2 }}>
                                    <InputLabel id="job-title">Job Title</InputLabel>
                                    <Select
                                        labelId="job-title"
                                        id="select-job-title"
                                        value={formData.title}
                                        onChange={(e) => setTitle(e.target.value)}
                                        label="Job Title"
                                        required
                                        MenuProps={MenuProps}

                                    >

                                        {jobTitles.map(title => (
                                            <MenuItem value={title} key={title}>{title}</MenuItem>
                                        ))}

                                    </Select>
                                </FormControl> */}

                                <TextField
                                    margin="normal"
                                    value={formData.title}
                                    onChange={(e) => setTitle(e.target.value)}
                                    fullWidth
                                    sx={{ my: 2 }}
                                    required
                                    label="Job Title"

                                />

                                <FormControl fullWidth sx={{ my: 2 }}>
                                    <InputLabel id="state-residence">Industry</InputLabel>
                                    <Select
                                        labelId="industry"
                                        id="select-Industry"
                                        required
                                        fullWidth
                                        value={formData.industry}

                                        label="Industry"
                                        sx={{ mb: 2 }}
                                        MenuProps={MenuProps}
                                        autoComplete="Industry"

                                    >

                                        {industries.map(industry => (
                                            <MenuItem value={industry} key={industry}>{industry}</MenuItem>
                                        ))}


                                    </Select>
                                </FormControl>

                                <FormControl fullWidth sx={{ my: 2 }}>
                                    <InputLabel id="location">Location</InputLabel>
                                    <Select
                                        labelId="location"
                                        id="select-location"
                                        required
                                        fullWidth
                                        value={formData.location}

                                        label="Location"

                                    >

                                        {countries.map(state => (
                                            <MenuItem value={state} key={state}>{state}</MenuItem>
                                        ))}


                                    </Select>
                                </FormControl>

                                <TextField
                                    margin="normal"
                                    value={formData.address}
                                    onChange={(e) => setAddress(e.target.value)}
                                    label="Office Address"
                                    fullWidth
                                    sx={{ my: 2 }}
                                    required


                                />


                                <FormControl fullWidth sx={{ my: 2 }}>
                                    <InputLabel id="qualification">Qualification</InputLabel>
                                    <Select
                                        labelId="qualification"
                                        id="select-qualification"
                                        value={formData.qualification}
                                        label="Qualification"
                                        required
                                        onChange={(e) => setQualification(e.target.value)}
                                    >

                                        <MenuItem value="HND/BSC">HND / BSC</MenuItem>
                                        <MenuItem value="MSc">MSc</MenuItem>
                                        <MenuItem value="PhD">PhD</MenuItem>
                                        <MenuItem value="Other">Other</MenuItem>

                                    </Select>
                                </FormControl>

                                {gradeOption && (<FormControl fullWidth sx={{ my: 2 }}>
                                    <InputLabel id="grade">Grade</InputLabel>
                                    <Select
                                        labelId="grade"
                                        id="select-grade"
                                        value={formData.grade}
                                        label="Grade"
                                        required
                                        onChange={(e) => setGrade(e.target.value)}
                                    >

                                        <MenuItem value="First Class">First Class</MenuItem>
                                        <MenuItem value="Second Class Upper">Second Class Upper</MenuItem>
                                        <MenuItem value="Second Class Lower">Second Class Lower</MenuItem>
                                        <MenuItem value="Third Class">Third Class</MenuItem>

                                    </Select>
                                </FormControl>
                                )}

                                <FormControl fullWidth>
                                    <InputLabel id="experience-level">Experience Level</InputLabel>
                                    <Select
                                        labelId="experience-level"
                                        id="select-experience"
                                        value={formData.experienceLevel}
                                        label="Experience Level"
                                        required
                                        onChange={(e) => setExperienceLevel(e.target.value)}
                                    >

                                        <MenuItem value="Entry-level">Entry-Level</MenuItem>
                                        <MenuItem value="Intermediate">Intermediate</MenuItem>
                                        <MenuItem value="Lead">Lead</MenuItem>
                                        <MenuItem value="Manager">Manager</MenuItem>
                                        <MenuItem value="Senior Manager">Senior Manager</MenuItem>

                                    </Select>
                                </FormControl>

                                <FormControl fullWidth sx={{ my: 2 }}>
                                    <InputLabel id="employment-type">Employment Type</InputLabel>
                                    <Select
                                        labelId="employment-type"
                                        id="select-employment-type"
                                        value={formData.type}
                                        label="Employment Type"
                                        required
                                        onChange={(e) => setType(e.target.value)}
                                    >

                                        <MenuItem value="Full-time">Full-Time</MenuItem>
                                        <MenuItem value="Part-Time">Part-Time</MenuItem>
                                        <MenuItem value="Freelance">Freelance</MenuItem>


                                    </Select>
                                </FormControl>
                                <FormControl fullWidth sx={{ my: 2 }}>
                                    <InputLabel id="work-mode">Work Environment</InputLabel>
                                    <Select
                                        labelId="work-mode"
                                        id="select-work-mode"
                                        value={formData.mode}
                                        required
                                        label="Work Environment"
                                        onChange={(e) => setMode(e.target.value)}
                                    >

                                        <MenuItem value="Onsite">Onsite</MenuItem>
                                        <MenuItem value="Hybrid">Hybrid</MenuItem>
                                        <MenuItem value="Remote">Remote</MenuItem>


                                    </Select>
                                </FormControl>
                                <Grid container >
                                    <Grid xs={4} >
                                        <TextField
                                            margin="normal"
                                            value={formData.minSalary}
                                            onChange={(e) => setMinSalary(e.target.value)}
                                            sx={{ width: "90%", marginRight: "5%" }}
                                            required
                                            label="Minimum Pay"

                                            autoComplete="Minimum Pay"
                                            autoFocus
                                        />
                                    </Grid>
                                    <Grid xs={4} >
                                        <TextField
                                            margin="normal"
                                            value={formData.maxSalary}
                                            onChange={(e) => setMaxSalary(e.target.value)}
                                            sx={{ width: "90%", marginRight: "5%" }}
                                            required
                                            label="Maximum Pay"

                                            autoComplete="Maximum Pay"
                                            autoFocus
                                        />
                                    </Grid>
                                    <Grid xs={4} sx={{ mt: 2, width: "90%", }}>
                                        <FormControl fullWidth>
                                            <InputLabel id="work-mode">Frequency</InputLabel>
                                            <Select
                                                labelId="frequency"
                                                id="select-frequency"
                                                value={formData.frequency}
                                                label="Frequency"
                                                onChange={(e) => setFrequency(e.target.value)}
                                            >

                                                <MenuItem value="Per Month">Per Month</MenuItem>
                                                <MenuItem value="Per Year">Per Year</MenuItem>



                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>




                                {/* <TextField
                                    id="outlined-multiline-static"
                                    label="Job Description"
                                    multiline
                                    fullWidth
                                    required
                                    rows={4}
                                    value={formData.description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    placeholder="List the details of this Job"
                                    sx={{ mt: 2 }}
                                /> */}

                                <ReactQuill
                                    value={formData.description}
                                    onChange={setDescription}
                                    placeholder="List the details of this Job"
                                    modules={{
                                        toolbar: [
                                            [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                            [{ size: [] }],
                                            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                            [{ 'list': 'ordered' }, { 'list': 'bullet' },
                                            { 'indent': '-1' }, { 'indent': '+1' }],
                                            ['link', 'image', 'video'],
                                            ['clean']
                                        ],
                                    }}
                                    formats={[
                                        'header', 'font', 'size',
                                        'bold', 'italic', 'underline', 'strike', 'blockquote',
                                        'list', 'bullet', 'indent',
                                        'link', 'image', 'video'
                                    ]}
                                    style={{ height: '200px', marginTop: "20px" }}
                                />
                                {/* <TextField
                                    id="outlined-multiline-static"
                                    label="Requirements"
                                    multiline
                                    fullWidth
                                    required
                                    value={formData.requirement}
                                    onChange={(e) => setRequirement(e.target.value)}
                                    rows={4}
                                    placeholder="Outline the requirements for this Job"
                                    sx={{ mt: 2 }}
                                /> */}

                                <ReactQuill
                                    value={formData.requirement}
                                    onChange={setDescription}
                                    placeholder="List the Requirements for this Job"
                                    modules={{
                                        toolbar: [
                                            [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                            [{ size: [] }],
                                            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                            [{ 'list': 'ordered' }, { 'list': 'bullet' },
                                            { 'indent': '-1' }, { 'indent': '+1' }],
                                            ['link', 'image', 'video'],
                                            ['clean']
                                        ],
                                    }}
                                    formats={[
                                        'header', 'font', 'size',
                                        'bold', 'italic', 'underline', 'strike', 'blockquote',
                                        'list', 'bullet', 'indent',
                                        'link', 'image', 'video'
                                    ]}
                                    style={{ height: '200px', marginTop: "100px", marginBottom: "80px" }}
                                />
                                <div>
                                    <FormControl sx={{ mt: 2, width: "100%" }}>
                                        <InputLabel id="demo-multiple-chip-label">Skill</InputLabel>
                                        <Select
                                            labelId="demo-multiple-skill-label"
                                            id="demo-multiple-skill"
                                            multiple
                                            value={formData.skills}

                                            input={<OutlinedInput id="select-multiple-chip" label="Skill" />}
                                            renderValue={(selected) => (
                                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                    {selected.map((value) => (
                                                        <Chip key={value} label={value} />
                                                    ))}
                                                </Box>
                                            )}
                                            MenuProps={MenuProps}
                                        >
                                            {professionalSkills.map((skill) => (
                                                <MenuItem
                                                    key={skill}
                                                    value={skill}
                                                    style={getStyles(skill, jobSkill, theme)}
                                                >
                                                    {skill}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>






                            </Box>
                        )
                    }

                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, mt: 5 }}>
                        <Button
                            color="inherit"
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            sx={{ mr: 1 }}
                            variant="text"
                            startIcon={<KeyboardBackspaceIcon />}
                        >
                            Back
                        </Button>
                        <Box sx={{ flex: '1 1 auto' }} />
                        {isStepOptional(activeStep) && (
                            <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                                Skip
                            </Button>
                        )}
                        <LoadingButton onClick={activeStep === steps.length - 1 ? handleSubmit :
                            activeStep === 0 ? handleStepOne : activeStep === 1 && handleStepTwo}
                            variant="contained"
                            endIcon={<ChevronRightIcon />}
                            size="small"
                            type="submit"
                            loading={isEditing ? loadingUpdate : loading}
                        >
                            {activeStep === steps.length - 1 ? 'Submit Job' : 'Continue'}
                        </LoadingButton>
                    </Box>
                </Fragment>
            )}
        </Box>
    );
}

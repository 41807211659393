export const JOB_LIST_REQUEST = "JOB_LIST_REQUEST";
export const JOB_LIST_SUCCESS = "JOB_LIST_SUCCESS";
export const JOB_LIST_FAIL = "JOB_LIST_FAIL";

export const DRAFT_LIST_REQUEST = "DRAFT_LIST_REQUEST";
export const DRAFT_LIST_SUCCESS = "DRAFT_LIST_SUCCESS";
export const DRAFT_LIST_FAIL = "DRAFT_LIST_FAIL";
export const DRAFT_LIST_RESET = "DRAFT_LIST_RESET";

export const PUBLISHED_JOB_LIST_REQUEST = "PUBLISHED_JOB_LIST_REQUEST";
export const PUBLISHED_JOB_LIST_SUCCESS = "PUBLISHED_JOB_LIST_SUCCESS";
export const PUBLISHED_JOB_LIST_FAIL = "PUBLISHED_JOB_LIST_FAIL";
export const PUBLISHED_JOB_LIST_RESET = "PUBLISHED_JOB_LIST_RESET";

export const CLOSED_JOB_LIST_REQUEST = "CLOSED_JOB_LIST_REQUEST";
export const CLOSED_JOB_LIST_SUCCESS = "CLOSED_JOB_LIST_SUCCESS";
export const CLOSED_JOB_LIST_FAIL = "CLOSED_JOB_LIST_FAIL";
export const CLOSED_JOB_LIST_RESET = "CLOSED_JOB_LIST_RESET";

export const REJECTED_JOB_LIST_REQUEST = "REJECTED_JOB_LIST_REQUEST";
export const REJECTED_JOB_LIST_SUCCESS = "REJECTED_JOB_LIST_SUCCESS";
export const REJECTED_JOB_LIST_FAIL = "REJECTED_JOB_LIST_FAIL";
export const REJECTED_JOB_LIST_RESET = "PUBLISHED_JOB_LIST_RESET";

export const JOB_DETAILS_REQUEST = "JOB_DETAILS_REQUEST";
export const JOB_DETAILS_SUCCESS = "JOB_DETAILS_SUCCESS";
export const JOB_DETAILS_FAIL = "JOB_DETAILS_FAIL";
export const JOB_DETAILS_RESET = "JOB_DETAILS_RESET";

export const JOB_DELETE_REQUEST = "JOB_DELETE_REQUEST";
export const JOB_DELETE_SUCCESS = "JOB_DELETE_SUCCESS";
export const JOB_DELETE_FAIL = "JOB_DELETE_FAIL";
export const JOB_DELETE_RESET = "JOB_DELETE_RESET";

export const JOB_CREATE_REQUEST = "JOB_CREATE_REQUEST";
export const JOB_CREATE_SUCCESS = "JOB_CREATE_SUCCESS";
export const JOB_CREATE_FAIL = "JOB_CREATE_FAIL";
export const JOB_CREATE_RESET = "JOB_CREATE_RESET";

export const JOB_PUBLISH_REQUEST = "JOB_PUBLISH_REQUEST";
export const JOB_PUBLISH_SUCCESS = "JOB_PUBLISH_SUCCESS";
export const JOB_PUBLISH_FAIL = "JOB_PUBLISH_FAIL";
export const JOB_PUBLISH_RESET = "JOB_PUBLISH_RESET";

export const JOB_APPLY_REQUEST = "JOB_APPLY_REQUEST";
export const JOB_APPLY_SUCCESS = "JOB_APPLY_SUCCESS";
export const JOB_APPLY_FAIL = "JOB_APPLY_FAIL";
export const JOB_APPLY_RESET = "JOB_APPLY_RESET";

export const JOB_CLOSE_REQUEST = "JOB_CLOSE_REQUEST";
export const JOB_CLOSE_SUCCESS = "JOB_CLOSE_SUCCESS";
export const JOB_CLOSE_FAIL = "JOB_CLOSE_FAIL";
export const JOB_CLOSE_RESET = "JOB_CLOSE_RESET";

export const JOB_REJECT_REQUEST = "JOB_REJECT_REQUEST";
export const JOB_REJECT_SUCCESS = "JOB_REJECT_SUCCESS";
export const JOB_REJECT_FAIL = "JOB_REJECT_FAIL";
export const JOB_REJECT_RESET = "JOB_REJECT_RESET";

export const APPLICATION_WITHDRAW_REQUEST = "APPLICATION_WITHDRAW_REQUEST";
export const APPLICATION_WITHDRAW_SUCCESS = "APPLICATION_WITHDRAW_SUCCESS";
export const APPLICATION_WITHDRAW_FAIL = "APPLICATION_WITHDRAW_FAIL";
export const APPLICATION_WITHDRAW_RESET = "APPLICATION_WITHDRAW_RESET";

export const JOB_WISHLIST_REQUEST = "JOB_WISHLIST_REQUEST";
export const JOB_WISHLIST_SUCCESS = "JOB_WISHLIST_SUCCESS";
export const JOB_WISHLIST_FAIL = "JOB_WISHLIST_FAIL";
export const JOB_WISHLIST_RESET = "JOB_WISHLIST_RESET";

export const JOB_UPDATE_REQUEST = "JOB_UPDATE_REQUEST";
export const JOB_UPDATE_SUCCESS = "JOB_UPDATE_SUCCESS";
export const JOB_UPDATE_FAIL = "JOB_UPDATE_FAIL";
export const JOB_UPDATE_RESET = "JOB_UPDATE_RESET";

export const MY_JOB_LIST_REQUEST = "MY_JOB_LIST_REQUEST";
export const MY_JOB_LIST_SUCCESS = "MY_JOB_LIST_SUCCESS";
export const MY_JOB_LIST_FAIL = "MY_JOB_LIST_FAIL";

export const UPDATE_FORM = "UPDATE_FORM"
export const RESET_FORM = "RESET_FORM"
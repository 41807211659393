import { Avatar, Box, Button, Chip, CircularProgress, Grid, Stack, TextField, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab';
import React, { Fragment, useEffect, useState } from 'react'
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { finalInterviewReset, getUserDetails, grantOffer, initialCallReset, scheduleFinalInterview, scheduleInitialCall, setItem, shortlistUser } from '../../../actions/userActions';
import { ArrowLeftIcon } from "@mui/x-date-pickers/icons";
import DownloadIcon from '@mui/icons-material/Download';

import ChevronRight from "@mui/icons-material/ChevronRight";
import Moment from 'react-moment';
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment';

import Modal from '@mui/material/Modal';
import { getJobDetails } from '../../../actions/jobActions';
import { downloadFile } from '../../../utils/Validate';



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: window.innerWidth * 0.45,
    bgcolor: 'background.paper',
    borderRadius: "25px",
    boxShadow: 24,
    p: 4,

};

function BasicModal(props) {

    const [firstname, setFirstname] = useState(props.firstname)
    const [lastname, setLastname] = useState(props.lastname)
    const [email, setEmail] = useState(props.email)
    const [location, setLocation] = useState("")
    const [startTime, setStartTime] = useState(moment().format("hh:mm"))
    const [endTime, setEndTime] = useState(moment().format("hh:mm"))
    const [date, setDate] = useState(moment().toDate())
    const [callLink, setCallLink] = useState("")


    const { id, applicantId } = useParams();
    const dispatch = useDispatch()
    const { success, loading, error } = useSelector(state => state.scheduleCall)
    const { success: successInterview, loading: loadingInterview, error: errorInterview } = useSelector(state => state.scheduleInterview)
    const { stage } = useSelector((state) => state.stageState);


    const handleSubmit = (e) => {
        e.preventDefault()

        const callData = {
            firstname,
            lastname,
            email,
            location,
            startTime,
            endTime,
            date: moment(date).format('dddd, MMM Do YYYY'),
            callLink
        };

        if (stage === "shortlisted") {
            dispatch(scheduleInitialCall(id, applicantId, callData))
        } else if (stage === "initialCall") {
            dispatch(scheduleFinalInterview(id, applicantId, callData))
        }


    }


    useEffect(() => {
        if (success) {
            toast("Call Scheduled Successfully", {
                className: 'toast-success',
                bodyClassName: 'toast-success',
            })
            dispatch(initialCallReset())
        }
        if (successInterview) {
            toast("Interview Scheduled Successfully", {
                className: 'toast-success',
                bodyClassName: 'toast-success',
            })
            dispatch(finalInterviewReset())
        }
    })


    return (
        <div>

            {error && toast(error, {
                className: 'toast-failed',
                bodyClassName: 'toast-failed',
            })}

            {errorInterview && toast(errorInterview, {
                className: 'toast-failed',
                bodyClassName: 'toast-failed',
            })}

            <Modal

                open={props.open}
                onClose={props.handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>

                    <div style={{ display: "flex", justifyContent: "space-between" }} >
                        <Typography variant='h4'>
                            {stage === "shortlisted" ? "Schedule Initial Call" : stage === "initialCall" && "Schedule Final Interview"}
                        </Typography>
                        <Typography variant='h5'
                            sx={{ cursor: "pointer", fontWeight: 800, color: "#ff499e" }}
                            onClick={props.handleClose}>X</Typography>
                    </div>


                    <form onSubmit={handleSubmit}>
                        <div style={{ display: "flex" }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                value={firstname}
                                onChange={(e) => setFirstname(e.target.value)}
                                label="First Name"
                                sx={{ mb: 2, mr: 1 }}
                                autoComplete="First Name"
                                autoFocus
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                sx={{ mb: 2, ml: 1 }}
                                label="Last name"
                                value={lastname}
                                onChange={(e) => setLastname(e.target.value)}
                                autoComplete="Last name"
                                autoFocus
                            />

                        </div>



                        <div style={{ display: "flex", justifyContent: "space-between" }}>

                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                sx={{ mb: 2, mr: 1 }}
                                label="Email"
                                type="email"
                                autoComplete="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}

                            />

                            <TextField
                                margin="normal"

                                fullWidth
                                sx={{ mb: 2, ml: 1 }}
                                label="Location"
                                value={location}
                                onChange={(e) => setLocation(e.target.value)}
                                autoComplete="Location"

                            />
                        </div>

                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            sx={{ mb: 2 }}
                            label="Call Link"
                            value={callLink}
                            onChange={(e) => setCallLink(e.target.value)}
                            autoComplete="Call Link"

                        />

                        <div style={{ display: "flex", justifyContent: "space-between" }}>


                            <TextField
                                margin="normal"
                                type="date"
                                label="Interview Date"
                                autoComplete="Interview Date"
                                value={moment(date).format('YYYY-MM-DD')}
                                onChange={(e) => setDate(moment(e.target.value, 'YYYY-MM-DD').toDate())}
                                required
                            />

                            <TextField
                                margin="normal"
                                type="time"
                                required
                                label="Start Time"
                                value={startTime}
                                onChange={(e) => setStartTime(e.target.value)}

                                autoComplete="Start Time"
                                autoFocus
                            />

                            <TextField
                                margin="normal"
                                type="time"
                                required
                                label="End Time"
                                value={endTime}
                                onChange={(e) => setEndTime(e.target.value)}

                                autoComplete="End Time"
                                autoFocus
                            />


                        </div>

                        <LoadingButton
                            variant="contained"
                            fullWidth
                            sx={{ mt: 3 }}
                            loading={stage === "shortlisted" ? loading : stage === "initialCall" && loadingInterview}
                            type="submit"

                        >{stage === "shortlisted" ? "Schedule Call" : stage === "initialCall" && "Schedule Interview"}
                        </LoadingButton>

                    </form>


                </Box>
            </Modal>
        </div>
    );
}


const Applicant = () => {
    const { userInfo } = useSelector((state) => state.userLogin);
    const userType = userInfo.type
    const { user, loading } = useSelector((state) => state.userDetails);
    const { success, error, loading: loadingShortlist } = useSelector((state) => state.userShortlist);
    const { success: successOffer, error: errorOffer, loading: loadingOffer } = useSelector((state) => state.grantOffer);
    const { id, applicantId } = useParams();

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const dispatch = useDispatch();
    const { stage } = useSelector((state) => state.stageState);

    const navigate = useNavigate()



    const handleSubmit = () => {


        if (stage === "applied") {
            dispatch(shortlistUser(id, applicantId))

            if (success) {

                return toast("Applicant Shortlisted", {
                    className: 'toast-success',
                    bodyClassName: 'toast-success',
                });
            } else {
                return toast(error, {
                    className: 'toast-failed',
                    bodyClassName: 'toast-failed',
                });
            }
        }

        if (stage === "shortlisted" || stage === "initialCall") {
            handleOpen()
        }

        if (stage === "finalInterview") {
            dispatch(grantOffer(id, applicantId))

            if (successOffer) {

                return toast("Offer Granted Successfully", {
                    className: 'toast-success',
                    bodyClassName: 'toast-success',
                });
            } else {
                return toast(errorOffer, {
                    className: 'toast-failed',
                    bodyClassName: 'toast-failed',
                });
            }
        }

    }

    useEffect(() => {
        if (!userInfo) {
            navigate("/login")

        }
        if ((user && !user.preferredTitle)) {
            dispatch(getUserDetails(applicantId));
        }
        if (success) {
            dispatch(getJobDetails(id))
        }


    }, [navigate, dispatch, applicantId, userInfo, success, user, id])
    return (
        <Grid container width="100%" >
            <ToastContainer />

            <Grid xs={2}></Grid>
            <Grid xs={8} >
                <BasicModal
                    open={open}
                    handleClose={handleClose}
                    firstname={user.firstname}
                    lastname={user.lastname}
                    email={user.email}
                />

                {loading ? <Typography><CircularProgress /></Typography> : (
                    <Fragment>


                        <Grid container mb={3}>
                            <Grid xs={4} style={{ display: "flex", alignItems: "center" }}>
                                <Avatar src={user.avatar} sx={{ width: 120, height: 120 }} alt={user.firstname} />
                            </Grid>
                            <Grid xs={8}>
                                <Typography variant="h6" fontWeight={600}>{user?.firstname} {user?.lastname}</Typography>
                                <Box sx={{ display: "flex", justifyContent: "flex-start", my: 1, fontSize: "16px" }}>
                                    <Typography sx={{ mr: 1 }}><AccountCircleOutlinedIcon /></Typography>
                                    <Typography>{user?.preferredTitle}</Typography>
                                </Box>
                                <Box sx={{ display: "flex", justifyContent: "flex-start", my: 1 }}>
                                    <Typography sx={{ mr: 1, }}><EmailOutlinedIcon /></Typography>
                                    <Typography>{user.email}</Typography>
                                </Box>

                                <Typography><span style={{ fontWeight: 600 }}>Profile ID:</span> {user?.profileId}</Typography>

                            </Grid>

                        </Grid>

                        <Typography variant="h6">Personal Information</Typography>

                        <Box sx={{
                            border: "1px solid #ebebeb",
                            px: 5, py: 2, my: 4, borderRadius: "10px"
                        }}>
                            <Box>
                                <TextField
                                    margin="normal"
                                    value={user.firstname}
                                    fullWidth
                                    required
                                    label="First Name"

                                    autoComplete="First Name"
                                    autoFocus
                                />
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    value={user.lastname}
                                    label="Last name"

                                    autoComplete="Last name"
                                    autoFocus
                                />

                                <TextField
                                    margin="normal"

                                    fullWidth
                                    type="number"


                                    label="Phone Number"
                                    value={user?.phone}
                                    sx={{ mb: 2 }}
                                    autoComplete="Phone Number"
                                    autoFocus
                                />

                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    value={user?.preferredTitle}
                                    label="Preferred Title"

                                    autoComplete="Preferred Title"
                                    autoFocus
                                />

                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    value={user?.stateOfResidence}
                                    label="State Of Residence"

                                    autoComplete="State Of Residence"
                                    autoFocus
                                />





                            </Box>
                        </Box>
                        <Typography variant="h6" mt={2}>Education Information</Typography>
                        {user.education?.length > 0 && user?.education?.map(edu => (
                            <Box sx={{
                                display: "flex", justifyContent: "space-between",
                                borderRadius: "10px", p: 3,
                                mb: 4,
                                border: "1px solid #eaeaea"
                            }}>
                                <div >
                                    <Typography variant="h6">{edu.name}</Typography>
                                    <Typography>{edu.field}</Typography>
                                </div>
                                <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>

                                    <Typography>
                                        <Moment format="MMM YYYY">{Date.parse(edu.date_start)}</Moment> -
                                        <Moment format="MMM YYYY">{Date.parse(edu.date_end)}</Moment>
                                    </Typography>
                                </div>
                            </Box>

                        ))}

                        <Typography variant="h6">Work Experience</Typography>
                        {user.experience && user.experience?.map(exp => (
                            <Grid container sx={{
                                borderRadius: "10px", p: 3,
                                mb: 4, border: "1px solid #eaeaea"
                            }}>
                                <Grid xs={6}>
                                    <Typography variant="h6">{exp.organization}</Typography>
                                    <Typography>{exp.title}</Typography>
                                    <Typography mt={1}>{exp.responsibilities}</Typography>


                                </Grid>
                                <Grid xs={2}></Grid>
                                <Grid xs={4} sx={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
                                    <Typography>
                                        <Moment format="MMM YYYY">{Date.parse(exp.date_start)}</Moment> -
                                        <Moment format="MMM YYYY">{Date.parse(exp.date_end)}</Moment>
                                    </Typography>
                                </Grid>

                            </Grid>
                        ))}


                        <Typography variant="h6">Skill</Typography>
                        {user.skills?.length > 0 && (
                            <Box sx={{
                                p: 3, border: "1px solid #eaeaea", borderRadius: "10px",
                                mb: 4
                            }}>
                                <Stack direction="row" spacing={1}>
                                    {user.skills?.slice(0, 5).map(skill => (
                                        <Chip key={skill} label={skill} variant="outlined" />
                                    ))}

                                </Stack>
                            </Box>

                        )}

                        {(userType === "Employer" || userType === "PortalAdmin") && (
                            <Box sx={{ display: "flex", justifyContent: "space-between", mt: 5 }}>
                                <Button
                                    variant="text"
                                    startIcon={<ArrowLeftIcon />}
                                    onClick={() => {
                                        dispatch(setItem("jobs"))
                                        navigate(`/jobs`)
                                    }}
                                >Back</Button>

                                {user?.resume && (

                                    <Button
                                        variant="outlined"
                                        startIcon={<DownloadIcon />}
                                        size="small"
                                        sx={{ fontSize: "12px" }}
                                        onClick={() =>
                                            downloadFile(
                                                `${window.location.origin
                                                }/uploads/${user?.resume.slice(9)}`
                                            )
                                        }
                                    >Download Resume</Button>


                                )}
                                <LoadingButton className="button-secondary" variant="contained" size='small' endIcon={<ChevronRight />} onClick={handleSubmit}
                                    loading={stage === "applied" ? loadingShortlist : stage === "finalInterview" && loadingOffer}
                                >
                                    {stage === "applied" ? "Shortlist Applicant" : stage === "shortlisted" ? "Schedule Initial Call" :
                                        stage === "initialCall" ? "Schedule Final Interview" : "Grant Offer"}
                                </LoadingButton>
                            </Box>
                        )}

                    </Fragment>
                )}
            </Grid>
            <Grid xs={2}></Grid>


        </Grid>
    )
}

export default Applicant
import { Avatar, Button, CircularProgress, Grid, Typography } from "@mui/material"
// import LinearCompany from "../../assets/images/linear.png"
import { useNavigate, useParams } from "react-router-dom"

import { setItem, setStage } from "../../actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getJobDetails } from "../../actions/jobActions";




const OfferApplicants = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { id } = useParams();

    const { job, loading } = useSelector((state) => state.jobDetails);

    useEffect(() => {

        if (!job._id || job._id !== id) {
            dispatch(getJobDetails(id))

        }
        dispatch(setStage("offer"))

    }, [job, id, dispatch])


    return (

        loading ? <Typography textAlign="center"> <CircularProgress /></Typography> : job.offer?.map(applicant => (
            <Grid container sx={{
                py: 2,
                px: 5, mt: 3,
                borderRadius: "10px",
                border: "1px solid #eaeaea",
                cursor: "pointer"
            }}
                onClick={() => {
                    dispatch(setItem("applicant"))
                    navigate(`/job/${job._id}/applicant/${applicant._id}`)
                }}
            >
                <Grid xs={1} sx={{ display: "flex", alignItems: "center" }}>
                    <Avatar src={applicant.avatar} alt={applicant.firstname} />
                </Grid>
                <Grid xs={11} sx={{ display: "flex", justifyContent: "space-between" }}>

                    <div>
                        <Typography sx={{ my: 1, fontSize: "16px", fontWeight: 600 }} >{applicant.preferredTitle}</Typography>
                        <Typography sx={{ my: 1, fontSize: "14px", }} >{applicant.firstname} {applicant.lastname}</Typography>
                    </div>

                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Button variant="outlined" onClick={() => {
                            dispatch(setItem("applicant"))
                            navigate(`/job/${job._id}/applicant/${applicant._id}`)
                        }}>View Profile</Button>
                    </div>

                </Grid>

            </Grid>
        ))


    )
}



export default OfferApplicants
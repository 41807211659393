import { Box, Button, Chip, CircularProgress, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Stack, TextField, Typography, useMediaQuery } from "@mui/material"
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';

import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CircularProgressWithLabel from "../../../UI/CircularProgressWithLabel";
import { useNavigate, useParams } from "react-router-dom";
import { getUserDetails, setItem, updateUserProfile, userProfileUpdateReset, setEditing } from "../../../actions/userActions";
import Moment from "react-moment";
import { LoadingButton } from "@mui/lab";
import DeleteIcon from '@mui/icons-material/Delete';
import Edit from "@mui/icons-material/Edit";
import axios from "axios";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import { ToastContainer, toast } from "react-toastify";
import { applyForJob, getJobDetails, resetJobApply } from "../../../actions/jobActions";
import ChevronRight from "@mui/icons-material/ChevronRight";
import { acceptOnlyNumbers, calculateProfileCompleteness, jobTitles, sanitizeInput, states } from "../../../utils/Validate";
import UploadButton from "../../../UI/UploadButton";
import { useTheme } from '@mui/material/styles';



const ITEM_HEIGHT = 30;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


const JobSeekerDetails = () => {



    const [stateOfResidence, setStateOfResidence] = useState("")
    const [isResumeUploaded, setIsResumeUploaded] = useState(false);


    const [firstname, setFirstname] = useState('')
    const [lastname, setLastname] = useState('')
    const [phone, setPhone] = useState(0)
    const [preferredTitle, setPreferredTitle] = useState('')
    const [avatar, setAvatar] = useState("")
    const [resume, setResume] = useState("")
    const [uploading, setUploading] = useState(false)
    const [avatarLoading, setAvatarLoading] = useState(false)
    const theme = useTheme()
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const isMidScreen = useMediaQuery(theme.breakpoints.down('md'));




    const { userInfo } = useSelector((state) => state.userLogin);
    const userType = userInfo.type
    const { id } = useParams()


    const { user, loading } = useSelector((state) => state.userDetails);

    const { item } = useSelector((state) => state.itemState);
    const { success: successApply, loading: loadingApply, error } = useSelector((state) => state.jobApply);

    const { success, loading: loadingUpdate } = useSelector((state) => state.userProfileUpdate);


    const dispatch = useDispatch();


    const navigate = useNavigate()


    const viewResume = async () => {
        try {
            const response = await fetch('/api/upload/view/resume', {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${userInfo.token}`,
                    'Content-Type': 'application/pdf',
                },
            });

            if (response.ok) {
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                window.open(url, '_blank');
            } else {
                const errorData = await response.json();
                console.error('Failed to fetch resume:', errorData.msg);
                return toast('Failed to fetch resume', {
                    className: 'toast-failed',
                    bodyClassName: 'toast-failed',
                });
            }
        } catch (error) {
            console.error('Error fetching resume:', error);
            // Optionally, display an error message to the user
        }
    };





    const uploadAvatarHandler = async (e) => {
        const file = e.target.files[0]
        const formData = new FormData()
        formData.append('avatar', file)
        setAvatarLoading(true)

        try {
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }

            const { data } = await axios.post('/api/upload/avatar', formData, config)


            setAvatar(data)
            setAvatarLoading(false)

        } catch (error) {
            console.error(error)
            setAvatarLoading(false)

        }
    }

    const handleApply = () => {
        try {
            dispatch(updateUserProfile({ id: user._id, resume }))
            setTimeout(() => {
                dispatch(applyForJob(id))
            }, 2000);

        } catch (err) {
            console.log(err)
        }

    }

    const uploadResumeHandler = async (e) => {
        const file = e.target.files[0]
        const formData = new FormData()
        formData.append('resume', file)
        setUploading(true)


        try {
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${userInfo.token}`,
                },
            }

            const res = await axios.post('/api/upload', formData, config)

            setUploading(false)
            setResume(res.data)
            if (res.data.success) {
                setIsResumeUploaded(true);
                return toast("Resume Parsed successfuly", {
                    className: 'toast-success',
                    bodyClassName: 'toast-success',
                });
            }


        } catch (error) {
            console.error(error)
            setUploading(false)
            if (error.response && error.response.data) {
                console.log(error.response.data);
                return toast(error.response.data.message, {
                    className: 'toast-failed',
                    bodyClassName: 'toast-failed',
                });
            } else {
                // Handle unexpected errors
                console.error('Unexpected error:', error);
                return toast('Something went wrong', {
                    className: 'toast-failed',
                    bodyClassName: 'toast-failed',
                });
            }
        }
    }




    const handleDeleteEducation = async (educationId) => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${userInfo.token}`,
                },
            }

            const res = await axios.delete(`/api/users/education/${educationId}`, config);
            if (res.data.success) {

                dispatch(getUserDetails("profile"))

                return toast('Education Deleted Successfully', {
                    className: 'toast-success',
                    bodyClassName: 'toast-success',
                });
            }


        } catch (error) {


            if (error.response && error.response.data) {
                console.log(error.response.data);
                return toast(error.response.data.message, {
                    className: 'toast-failed',
                    bodyClassName: 'toast-failed',
                });
            } else {
                // Handle unexpected errors
                console.error('Unexpected error:', error);
                return toast('Something went wrong', {
                    className: 'toast-failed',
                    bodyClassName: 'toast-failed',
                });
            }
        }
    };

    const handleDeleteExperience = async (experienceId) => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${userInfo.token}`,
                },
            }

            const res = await axios.delete(`/api/users/experience/${experienceId}`, config);
            if (res.data.success) {

                dispatch(getUserDetails("profile"))

                return toast('Experience Deleted Successfully', {
                    className: 'toast-success',
                    bodyClassName: 'toast-success',
                });
            }


        } catch (error) {


            if (error.response && error.response.data) {
                console.log(error.response.data);
                return toast(error.response.data.message, {
                    className: 'toast-failed',
                    bodyClassName: 'toast-failed',
                });
            } else {
                // Handle unexpected errors
                console.error('Unexpected error:', error);
                return toast('Something went wrong', {
                    className: 'toast-failed',
                    bodyClassName: 'toast-failed',
                });
            }
        }
    };

    const handleDeleteSkill = async (skill) => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${userInfo.token}`,
                },
            }

            const res = await axios.delete(`/api/users/skills/${skill}`, config);
            if (res.data.success) {

                dispatch(getUserDetails("profile"))

                return toast('Skill Deleted Successfully', {
                    className: 'toast-success',
                    bodyClassName: 'toast-success',
                });
            }


        } catch (error) {


            if (error.response && error.response.data) {
                console.log(error.response.data);
                return toast(error.response.data.message, {
                    className: 'toast-failed',
                    bodyClassName: 'toast-failed',
                });
            } else {
                // Handle unexpected errors
                console.error('Unexpected error:', error);
                return toast('Something went wrong', {
                    className: 'toast-failed',
                    bodyClassName: 'toast-failed',
                });
            }
        }
    };

    const updateUserHandler = (e) => {
        e.preventDefault();

        try {
            dispatch(updateUserProfile({ id: user._id, firstname, lastname, stateOfResidence, preferredTitle, phone, avatar, resume }));


        } catch (error) {
            console.log(error.msg)
        }

    };

    useEffect(() => {
        if (!userInfo) {
            navigate("/login")
        } else if (!user || !user.firstname || success || successApply || isResumeUploaded) {
            dispatch(getUserDetails('profile'));
            setIsResumeUploaded(false);
            dispatch(userProfileUpdateReset());

        } else {
            setFirstname(user.firstname);
            setLastname(user.lastname);
            setStateOfResidence(user.stateOfResidence)
            setPhone(Number(user.phone))
            setPreferredTitle(user.preferredTitle)
            setAvatar(user.avatar)
            setResume(user.resume)
        }

        if (error) {
            toast(error, {
                className: 'toast-failed',
                bodyClassName: 'toast-failed',
            })
            dispatch(resetJobApply())
        }

        if (successApply) {
            dispatch(getJobDetails(id))
            toast("Application Successful", {
                className: 'toast-success',
                bodyClassName: 'toast-success',
            })

            setTimeout(() => {
                dispatch(setItem("applications"))
                navigate('/applications');
            }, 3000);
            dispatch(resetJobApply())

        }
    }, [user, userInfo, dispatch, success, successApply, navigate, id, error, isResumeUploaded])

    return (
        <Grid container width="100%" mt={3}>


            <ToastContainer />


            <Grid md={9} xs={12} >


                {loading ? <Typography>Loading...</Typography> : (

                    <Fragment>


                        <Grid container mb={3}>
                            <Grid md={6} lg={3} sm={12} xs={12} style={{ display: "flex", alignItems: "center" }}>
                                <div className="personal-image" >
                                    <label className="label">
                                        <input type="file" onChange={uploadAvatarHandler} />
                                        <figure className="personal-figure">
                                            <img src={avatar} className="personal-avatar" alt="avatar" />
                                            <figcaption className="personal-figcaption">
                                                <img src="https://raw.githubusercontent.com/ThiagoLuizNunes/angular-boilerplate/master/src/assets/imgs/camera-white.png" alt="Camera" />
                                            </figcaption>
                                        </figure>
                                    </label>
                                </div>


                                {avatarLoading && <Typography><CircularProgress /></Typography>}
                            </Grid>
                            <Grid md={6} lg={5} sm={12} xs={12}>
                                <Typography variant="h6" mt={3} fontWeight={600}>{user?.firstname} {user?.lastname}</Typography>
                                <Box sx={{ display: "flex", justifyContent: "flex-start", my: 1, fontSize: "16px" }}>
                                    <Typography sx={{ mr: 1 }}><AccountCircleOutlinedIcon /></Typography>
                                    <Typography>{preferredTitle}</Typography>
                                </Box>
                                <Box sx={{ display: "flex", justifyContent: "flex-start", my: 1 }}>
                                    <Typography sx={{ mr: 1, }}><EmailOutlinedIcon /></Typography>
                                    <Typography>{user?.email}</Typography>
                                </Box>

                                <Typography><span style={{ fontWeight: 600 }}>Profile ID:</span> {user?.profileId}</Typography>

                            </Grid>
                            {(userType === "JobSeeker") && (
                                <Grid lg={4} xs={12} sm={6} md={6}>
                                    <Typography sx={{ textAlign: isSmallScreen ? "left" : "center" }} mt={3} mb={1} fontWeight={500}><strong>Profile Completeness</strong></Typography>
                                    <Typography sx={{ textAlign: isSmallScreen ? "left" : "center" }}>
                                        <CircularProgressWithLabel value={calculateProfileCompleteness(user)} />

                                    </Typography>
                                </Grid>
                            )}
                        </Grid>

                        <Typography variant="h6">Personal Information</Typography>
                        <Typography sx={{ fontSize: "12px" }}>Update Your Personal Information</Typography>
                        <Box sx={{
                            border: "1px solid #ebebeb",
                            px: isSmallScreen ? 1 : 5, py: 2, my: 4, borderRadius: "10px"
                        }}>
                            <form onSubmit={updateUserHandler}>
                                <TextField
                                    margin="normal"
                                    value={sanitizeInput(firstname)}
                                    fullWidth
                                    required
                                    label="First Name"
                                    onChange={(e) => setFirstname(e.target.value)}
                                    autoComplete="First Name"
                                    autoFocus
                                />
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    value={sanitizeInput(lastname)}
                                    label="Last name"
                                    onChange={(e) => setLastname(e.target.value)}
                                    autoComplete="Last name"
                                    autoFocus
                                />

                                <TextField
                                    margin="normal"

                                    fullWidth

                                    onChange={(e) => setPhone(e.target.value)}

                                    label="Phone Number"
                                    value={typeof phone === "number" ? phone : acceptOnlyNumbers(phone)}
                                    sx={{ mb: 2 }}
                                    autoComplete="Phone Number"
                                    autoFocus
                                />

                                <FormControl fullWidth sx={{ my: 3 }}>
                                    <InputLabel id="preferred-title">Preferred Title</InputLabel>
                                    <Select
                                        labelId="preferred-title"
                                        id="select-preferred-title"
                                        value={preferredTitle}
                                        label="Preferred Title"
                                        required
                                        MenuProps={MenuProps}
                                        onChange={(e) => setPreferredTitle(e.target.value)}
                                    >

                                        {jobTitles.map(title => (
                                            <MenuItem value={title} key={title}>{title}</MenuItem>
                                        ))}

                                    </Select>
                                </FormControl>

                                <FormControl fullWidth sx={{ my: 3 }}>
                                    <InputLabel id="state-residence">State of Residence</InputLabel>
                                    <Select
                                        labelId="state-residence"
                                        id="select-state-residence"
                                        value={stateOfResidence}
                                        label="State Of Residence"
                                        required
                                        MenuProps={MenuProps}
                                        onChange={(e) => setStateOfResidence(e.target.value)}
                                    >
                                        {states.map(state => (
                                            <MenuItem value={state} key={state}>{state}</MenuItem>
                                        ))}



                                    </Select>
                                </FormControl>

                                {userType === "JobSeeker" && (
                                    <Typography sx={{ textAlign: "right" }}>
                                        <LoadingButton
                                            type="submit"
                                            loading={loadingUpdate}
                                            variant="contained"

                                            sx={{ my: 3 }}
                                        >
                                            Save
                                        </LoadingButton>
                                    </Typography>
                                )}

                            </form>
                        </Box>
                        <Typography variant="h6" mt={2}>
                            Education Information<Button
                                variant="text" sx={{ fontSize: "10px" }}
                                onClick={() => {
                                    dispatch(setItem("edit-profile"))
                                    dispatch(setEditing(false))
                                    navigate({
                                        pathname: "/edit-profile",
                                        search: "?activestep=1"
                                    }
                                    )
                                }}
                            >[+Add]</Button>
                        </Typography>
                        {user.education?.length > 0 ? user?.education?.map(edu => (
                            <Box sx={{
                                display: "flex", justifyContent: "space-between",
                                borderRadius: "10px", p: 3,
                                mb: 4,
                                border: "1px solid #eaeaea"
                            }}>
                                <div >
                                    <Typography variant="h6">{edu.name}</Typography>
                                    <Typography>{edu.field}</Typography>
                                </div>
                                <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
                                    <Typography>
                                        <IconButton

                                            onClick={() => {
                                                dispatch(setItem("education"))
                                                dispatch(setEditing(true))
                                                navigate(`/profile/education/${edu._id}`)

                                            }}
                                            aria-label="edit">
                                            <Edit />
                                        </IconButton>
                                        <IconButton aria-label="delete"
                                            onClick={() => handleDeleteEducation(edu._id)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </Typography>
                                    <Typography>
                                        <Moment format="MMM YYYY">{Date.parse(edu.date_start)}</Moment> -
                                        {" "}<Moment format="MMM YYYY">{Date.parse(edu.date_end)}</Moment>
                                    </Typography>
                                </div>
                            </Box>

                        )) : (
                            <Box sx={{
                                display: "flex", justifyContent: "space-between",
                                borderRadius: "10px", p: 3,
                                mb: 4,
                                border: "1px solid #eaeaea"
                            }}>
                                <Typography textAlign="center">
                                    <Button className="button-primary"
                                        onClick={() => {
                                            dispatch(setItem("edit-profile"))
                                            navigate({
                                                pathname: "/edit-profile",
                                                search: "?activestep=1"
                                            }
                                            )
                                        }}

                                        variant="outlined"

                                        sx={{ mt: 2, mb: 2 }}
                                    >
                                        Add Education
                                    </Button>
                                </Typography>
                            </Box>
                        )}

                        <Typography variant="h6">Work Experience <Button
                            variant="text" sx={{ fontSize: "10px" }}
                            onClick={() => {
                                dispatch(setItem("edit-profile"))
                                dispatch(setEditing(false))
                                navigate({
                                    pathname: "/edit-profile",
                                    search: "?activestep=2"
                                }
                                )
                            }}
                        >[+Add]</Button></Typography>
                        {user?.experience?.length > 0 ? user?.experience?.map(exp => (
                            <Fragment>
                                <Grid container sx={{
                                    borderRadius: "10px", p: 3,
                                    mb: 4, border: "1px solid #eaeaea"
                                }}>
                                    <Grid xs={6}>
                                        <Typography variant="h6">{exp.organization}</Typography>
                                        <Typography>{exp.title}</Typography>
                                        <Typography mt={1}>{exp.responsibilities}</Typography>


                                    </Grid>
                                    <Grid xs={2}></Grid>
                                    <Grid xs={4} sx={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
                                        <Typography>
                                            <IconButton
                                                onClick={() => {
                                                    dispatch(setItem("experience"))
                                                    dispatch(setEditing(true))
                                                    navigate(`/profile/experience/${exp._id}`)

                                                }}
                                                aria-label="edit">
                                                <Edit />
                                            </IconButton>
                                            <IconButton aria-label="delete"
                                                onClick={() => handleDeleteExperience(exp._id)}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </Typography>
                                        <Typography>
                                            <Moment format="MMM YYYY">{Date.parse(exp.date_start)}</Moment> -
                                            {" "}<Moment format="MMM YYYY">{Date.parse(exp.date_end)}</Moment>
                                        </Typography>
                                    </Grid>

                                </Grid>

                            </Fragment>
                        )) : (
                            <Box sx={{
                                display: "flex", justifyContent: "space-between",
                                borderRadius: "10px", p: 3,
                                mb: 4,
                                border: "1px solid #eaeaea"
                            }}>
                                <Typography textAlign="center">
                                    <Button className="button-primary"
                                        onClick={() => {
                                            dispatch(setItem("edit-profile"))
                                            navigate({
                                                pathname: "/edit-profile",
                                                search: "?activestep=2"
                                            }
                                            )
                                        }}

                                        variant="outlined"

                                        sx={{ mt: 2, mb: 2 }}
                                    >
                                        Add Experience
                                    </Button>
                                </Typography>
                            </Box>
                        )}


                        <Typography variant="h6">Skill
                            <Button
                                variant="text" sx={{ fontSize: "10px" }}
                                onClick={() => {
                                    dispatch(setItem("edit-profile"))
                                    dispatch(setEditing(false))
                                    navigate({
                                        pathname: "/edit-profile",
                                        search: "?activestep=3"
                                    }
                                    )
                                }}
                            >[+Add]</Button>
                        </Typography>
                        {user.skills?.length > 0 ? (
                            <Box sx={{
                                p: 3, border: "1px solid #eaeaea", borderRadius: "10px",
                                mb: 4
                            }}>
                                <Stack direction={isMidScreen ? "column" : "row"} spacing={1}>
                                    {user.skills?.slice(0, 4).map(skill => (
                                        <Chip key={skill} label={skill} variant="outlined"
                                            onDelete={() => handleDeleteSkill(skill)} />
                                    ))}

                                </Stack>
                            </Box>

                        ) : (
                            <Box sx={{
                                p: 3, border: "1px solid #eaeaea", borderRadius: "10px",
                                mb: 4
                            }}>
                                <Typography textAlign="center">
                                    <Button className="button-primary"
                                        onClick={() => {
                                            dispatch(setItem("edit-profile"))
                                            navigate({
                                                pathname: "/edit-profile",
                                                search: "?activestep=3"
                                            }
                                            )
                                        }}

                                        variant="outlined"

                                        sx={{ mt: 4, mb: 2 }}
                                    >
                                        Add Skill
                                    </Button>
                                </Typography>


                            </Box>


                        )}




                        {(item === "apply") && (
                            <div
                                style={{ display: "flex", justifyContent: "space-between" }}
                            >
                                <Button variant="text" startIcon={<ChevronLeft />}>Back</Button>
                                <LoadingButton
                                    onClick={handleApply}
                                    loading={loadingApply}
                                    variant='contained'
                                    endIcon={<ChevronRight />}
                                    className="button-primary"
                                >Apply</LoadingButton>
                            </div>
                        )}



                    </Fragment>
                )}
            </Grid>
            <Grid md={3} xs={12} sm={6}

            >
                {userInfo.type === "JobSeeker" && (
                    <Box border="1px solid #eaeaea"
                        p={1}
                        m={1}
                        borderRadius="10px"
                        textAlign="center"
                    >
                        <Typography variant="h6" my={2}>Update Resume (PDF ONLY)</Typography>


                        <Box my={3}>
                            <UploadButton size="small" style={{ fontSize: "12px" }} text="Upload Resume" onChange={uploadResumeHandler} loading={uploading} />
                        </Box>
                    </Box>

                )}



                {user.resume && (
                    <Box my={3} display="flex" justifyContent="center">
                        <Button
                            variant="outlined"
                            startIcon={<VisibilityIcon />}
                            size="small"
                            sx={{ fontSize: "12px" }}
                            onClick={viewResume}

                        >View Resume</Button>

                    </Box>
                )}







            </Grid>


        </Grid>
    )
}

export default JobSeekerDetails
// import { configureStore } from "@reduxjs/toolkit";
// import { adminListReducer, editingReducer, educationDetailsReducer, employerListReducer, experienceDetailsReducer, grantOfferReducer, itemReducer, scheduleCallReducer, scheduleInterviewReducer, seekerFormReducer, seekerListReducer, stageReducer, userCreateReducer, userDeleteReducer, userDetailsReducer, userListReducer, userLoginReducer, userProfileUpdateReducer, userReactivateReducer, userRegisterReducer, userShortListReducer, userSuspendReducer, userVerifyReducer } from "./reducers/userReducer";

// import { applicationWithdrawReducer, draftListReducer, formReducer, jobApplyReducer, jobCloseReducer, jobCreateReducer, jobDeleteReducer, jobDetailsReducer, jobListReducer, jobPublishReducer, jobRejectReducer, jobUpdateReducer, jobWishlistReducer, myJobListReducer, publishedJobListReducer } from "./reducers/jobReducer";
// import { subscribeReducer } from "./reducers/paymentReducer";
// import { myTicketListReducer, ticketCloseReducer, ticketCreateReducer, ticketDeleteReducer, ticketDetailsReducer, ticketListReducer } from "./reducers/ticketReducer";

// const localStorageUserInfo = localStorage.getItem("userInfo")
//     ? JSON.parse(localStorage.getItem("userInfo"))
//     : null;


// const preloadedState = {
//     userLogin: {
//         userInfo: localStorageUserInfo,
//     },
// }

// const store = configureStore({
//     reducer: {
// userRegister: userRegisterReducer,
// userLogin: userLoginReducer,
// userProfileUpdate: userProfileUpdateReducer,
// userDetails: userDetailsReducer,
// userVerify: userVerifyReducer,
// userList: userListReducer,
// seekerList: seekerListReducer,
// employerList: employerListReducer,
// adminList: adminListReducer,
// userCreate: userCreateReducer,
// userDelete: userDeleteReducer,
// userSuspend: userSuspendReducer,
// userReactivate: userReactivateReducer,
// jobCreate: jobCreateReducer,
// myJobList: myJobListReducer,
// jobList: jobListReducer,
// draftList: draftListReducer,
// jobWishlist: jobWishlistReducer,
// publishedJobList: publishedJobListReducer,
// jobDetails: jobDetailsReducer,
// jobUpdate: jobUpdateReducer,
// jobDelete: jobDeleteReducer,
// jobPublish: jobPublishReducer,
// jobClose: jobCloseReducer,
// jobReject: jobRejectReducer,
// applicationWithdraw: applicationWithdrawReducer,
// formReducer,
// subscribe: subscribeReducer,
// seekerForm: seekerFormReducer,
// jobApply: jobApplyReducer,
// itemState: itemReducer,
// stageState: stageReducer,
// editingState: editingReducer,
// userShortlist: userShortListReducer,
// scheduleCall: scheduleCallReducer,
// scheduleInterview: scheduleInterviewReducer,
// grantOffer: grantOfferReducer,
// educationDetails: educationDetailsReducer,
// experienceDetails: experienceDetailsReducer,
// myTicketList: myTicketListReducer,
// ticketList: ticketListReducer,
// ticketDetails: ticketDetailsReducer,
// ticketDelete: ticketDeleteReducer,
// ticketCreate: ticketCreateReducer,
// ticketClose: ticketCloseReducer
//     },
//     preloadedState
// })

// export default store



// src/redux/store.js

import { configureStore, combineReducers } from '@reduxjs/toolkit';
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // Uses localStorage for web


// Import your reducers
import { adminListReducer, editingReducer, educationDetailsReducer, employerListReducer, experienceDetailsReducer, grantOfferReducer, itemReducer, scheduleCallReducer, scheduleInterviewReducer, seekerFormReducer, seekerListReducer, stageReducer, userCreateReducer, userDeleteReducer, userDetailsReducer, userListReducer, userLoginReducer, userProfileUpdateReducer, userReactivateReducer, userRegisterReducer, userShortListReducer, userSuspendReducer, userVerifyReducer } from "./reducers/userReducer";

import { applicationWithdrawReducer, draftListReducer, formReducer, jobApplyReducer, jobCloseReducer, jobCreateReducer, jobDeleteReducer, jobDetailsReducer, jobListReducer, jobPublishReducer, jobRejectReducer, jobUpdateReducer, jobWishlistReducer, myJobListReducer, publishedJobListReducer } from "./reducers/jobReducer";
import { subscribeReducer } from "./reducers/paymentReducer";
import { myTicketListReducer, ticketCloseReducer, ticketCreateReducer, ticketDeleteReducer, ticketDetailsReducer, ticketListReducer } from "./reducers/ticketReducer";


const localStorageUserInfo = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null;


const preloadedState = {
    userLogin: {
        userInfo: localStorageUserInfo,
    },
}
// Import other reducers as needed

// Combine all reducers into a root reducer
const rootReducer = combineReducers({
    userRegister: userRegisterReducer,
    userLogin: userLoginReducer,
    userProfileUpdate: userProfileUpdateReducer,
    userDetails: userDetailsReducer,
    userVerify: userVerifyReducer,
    userList: userListReducer,
    seekerList: seekerListReducer,
    employerList: employerListReducer,
    adminList: adminListReducer,
    userCreate: userCreateReducer,
    userDelete: userDeleteReducer,
    userSuspend: userSuspendReducer,
    userReactivate: userReactivateReducer,
    jobCreate: jobCreateReducer,
    myJobList: myJobListReducer,
    jobList: jobListReducer,
    draftList: draftListReducer,
    jobWishlist: jobWishlistReducer,
    publishedJobList: publishedJobListReducer,
    jobDetails: jobDetailsReducer,
    jobUpdate: jobUpdateReducer,
    jobDelete: jobDeleteReducer,
    jobPublish: jobPublishReducer,
    jobClose: jobCloseReducer,
    jobReject: jobRejectReducer,
    applicationWithdraw: applicationWithdrawReducer,
    formReducer,
    subscribe: subscribeReducer,
    seekerForm: seekerFormReducer,
    jobApply: jobApplyReducer,
    itemState: itemReducer,
    stageState: stageReducer,
    editingState: editingReducer,
    userShortlist: userShortListReducer,
    scheduleCall: scheduleCallReducer,
    scheduleInterview: scheduleInterviewReducer,
    grantOffer: grantOfferReducer,
    educationDetails: educationDetailsReducer,
    experienceDetails: experienceDetailsReducer,
    myTicketList: myTicketListReducer,
    ticketList: ticketListReducer,
    ticketDetails: ticketDetailsReducer,
    ticketDelete: ticketDeleteReducer,
    ticketCreate: ticketCreateReducer,
    ticketClose: ticketCloseReducer
    // Add other reducers here
});

// Configure persist settings
const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['item',], // Specify which reducers to persist
};

// Create a persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configure the Redux store
const store = configureStore({
    reducer: persistedReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                // Ignore redux-persist actions to prevent serialization warnings
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }), // Add thunk middleware
    devTools: process.env.NODE_ENV !== 'production', // Enable Redux DevTools in development
});

// Create a persistor for the store
const persistor = persistStore(store);

// Export the store and persistor
export { store, persistor };
